import Cookies from "js-cookie"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

// MUI
import { AccountBalance, AccountBalanceWallet, CallMissedOutgoing, Close, CopyAll, Diamond, GetApp, Groups, MilitaryTech, Quiz, Receipt, Redeem, RocketLaunch, Share, StickyNote2, SupportAgent, Task, Telegram, WhatsApp } from "@mui/icons-material"

import { Box, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, TextField, Typography } from "@mui/material"

import Lottie from "lottie-react"
import shareAnimation from "../lottie/share.json"

import MyContext from "../context/context"
import Win from "./win"

import axios from "axios"
import BalanceUser from "./balance"

import JobAnimation from "../lottie/job.json"

export default function ProfileUser({ fullscreen, snackbar }) {
	useEffect(() => {
		window.scroll(0, 0)
	}, [])
	const [open, setOpen] = useState("")
	const [telegram, setTelegram] = useState(false)
	const [action, setAction] = useState("")
	const navi = useNavigate()
	const [checkin, setChecking] = useState([])
	const [job, setJob] = useState(false)

	const api = useContext(MyContext)

	const [profile, setProfile] = useState([])
	const [wallet, setWallet] = useState([])
	const [team, setTeam] = useState([])
	useEffect(() => {
		get_profile()
	}, [])

	const get_profile = () => {
		axios.get(`${api.api.user}?profile`).then(function (res) {
			res.data.profile.status === true && setProfile(res.data.profile.data)
			res.data.wallet.status === true && setWallet(res.data.wallet.data)
			setTeam(res.data.team)
			if (res.data.bank.status === true) {
				setBank(res.data.bank.data.bank_name)
				setName(res.data.bank.data.name)
				setIfsc(res.data.bank.data.ifsc)
				setAccount(res.data.bank.data.account)
			}
		})
	}

	const [bank, setBank] = useState("")
	const [name, setName] = useState("")
	const [ifsc, setIfsc] = useState("")
	const [account, setAccount] = useState("")
	const add_bank = () => {
		setAction("addingbank")
		var formData = new FormData()
		formData.append("add-bank", bank)
		formData.append("name", name)
		formData.append("ifsc", ifsc)
		formData.append("account", account)
		axios.post(api.api.user, formData).then(function (res) {
			setAction("")
			snackbar(res.data.msg)
			res.data.status === true && setOpen("")
		})
	}

	const handleCopy = (event) => {
		navigator.clipboard
			.writeText(event)
			.then(() => snackbar("Copied to clipboard"))
			.catch(() => snackbar("Failed to copy"))
	}

	const [giftid, setGiftid] = useState("")
	const redeem_gift = () => {
		setAction("redeem")
		var formData = new FormData()
		formData.append("redeem-gift", giftid)
		axios.post(api.api.user, formData).then(function (res) {
			setAction("")
			setOpen("")
			if (res.data.status === true) {
				get_profile()
				setChecking(res.data)
			} else {
				snackbar(res.data.msg)
			}
		})
	}

	const levelicon = String(team.level).toLowerCase()

	const teams = Number(team.team)
	const recharge = Number(wallet.recharge)

	const links = [
		{ name: "Novice", amount: 1, icon: "/img/novice.png" },
		{ name: "Amateur", amount: 3, icon: "/img/amateur.png" },
		{ name: "Explorer", amount: 10, icon: "/img/explorer.png" },
		{ name: "Elite", amount: 20, icon: "/img/elite.png" },
		{ name: "Master", amount: 30, icon: "/img/master.png" },
		{ name: "Legend", amount: 50, icon: "/img/legend.png" },
		{ name: "Demigod", amount: 100, icon: "/img/demigod.png" },
		{ name: "Assistant", amount: 200, icon: "/img/assistant.png" },
		{ name: "Manager", amount: 500, icon: "/img/manager.png" },
	]

	const daily_check = () => {
		setAction("checking")
		var formData = new FormData()
		formData.append("daily-check-in", "")
		axios.post(api.api.user, formData).then(function (res) {
			setOpen("")
			setAction("")
			if (res.data.status === true) {
				setChecking(res.data)
			} else {
				snackbar(res.data.msg)
			}
		})
	}

	useEffect(() => {
		getnotifications()
	}, [])
	const [noti, setNoti] = useState([])
	const getnotifications = () => {
		if (!Cookies.get("notification") === true) {
			axios.get(`${api.api.user}?get-notifications`).then(function (res) {
				if (res.data.status === true) {
					setNoti(res.data.data)
					setOpen("notification")
				} else {
					setNoti(res.data)
					setTelegram(true)
				}
			})
		} else {
			setTelegram(true)
		}
	}

	return (
		<Box className="content" mb={10}>
			<Win dialog={checkin.status} damt={checkin.amount} dmsg={checkin.msg} />

			<Dialog
				open={job}
				fullWidth
				PaperProps={{ style: { borderRadius: 10 } }}
				onClose={() => {
					setJob(false)
				}}
			>
				<DialogContent>
					<Box>
						<Lottie animationData={JobAnimation} style={{ width: 250, margin: "auto" }} />
					</Box>
					<Box mt={2}>
						<Button variant="contained" fullWidth color="primary" onClick={() => navi("/user/job")}>
							Apply for Job!
						</Button>

						<Typography lineHeight={1.6} mt={2} fontSize={12} color={"gray"}>
							1. Complete your profile to apply for job.
							<br />
							2. Complete your KYC to apply for job.
							<br />
							3. Complete your bank details to apply for job.
							<br />
							4. Limited sits available.
							<br />
						</Typography>
					</Box>
				</DialogContent>
			</Dialog>

			<Dialog
				open={telegram}
				fullWidth
				onClose={() => {
					setTelegram(false)
					setJob(true)
				}}
			>
				<DialogContent>
					<Box textAlign={"end"}>
						<IconButton
							onClick={() => {
								setTelegram(false)
								setJob(true)
							}}
						>
							<Close />
						</IconButton>
					</Box>
					<Box>
						<Box textAlign={"center"}>
							<img src="/img/telegram.png" style={{ width: "100px" }} />
							<Typography mt={1} variant="h5" fontWeight={"bold"}>
								Join our Telegram Group
							</Typography>
						</Box>

						<Typography mt={3} variant="body2" color={"gray"}>
							1. Be the first to receive exciting updates, tips, and exclusive offers!
						</Typography>
						<Typography mt={1} variant="body2" color={"gray"}>
							2. Claim a Daily Gift Card distributed every morning at 7:00 PM – don’t miss your lucky chance!
						</Typography>
						<Typography mt={1} variant="body2" color={"gray"}>
							3. Get insider strategies, expert insights, and connect with a vibrant community of enthusiasts!
						</Typography>
						<Typography mt={1} variant="body2" color={"gray"}>
							4. Access exclusive contests and giveaways only available to group members.
						</Typography>

						<Stack direction={"column"} spacing={1} justifyContent={"center"} mt={3}>
							<Button startIcon={<Telegram />} variant="contained" fullWidth color="primary" target="_blank" rel="noopener noreferrer" href="https://t.me/aircraftmfg">
								Join Telegram
							</Button>
						</Stack>
					</Box>
				</DialogContent>
			</Dialog>

			<Dialog
				open={open === "notification" ? true : false}
				fullWidth
				onClose={() => {
					setOpen("")
					Cookies.set("notification", true, { expires: 3 / 24 })
					setTelegram(true)
				}}
			>
				<DialogTitle>
					<Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
						<Typography>Notification</Typography>
						<IconButton onClick={() => setOpen("")}>
							<Close />
						</IconButton>
					</Stack>
				</DialogTitle>
				<Divider />
				<DialogContent>
					{noti.status !== false
						? noti.map((row, i) => (
								<Card elevation={0} key={i} sx={{ border: "1px dashed #E5E4E2", mb: 1 }}>
									<CardContent>
										<Typography mt={1} fontSize={14} fontWeight={600} color={row.noti_color}>
											{row.description}
										</Typography>
										<Typography mt={1} color={"gray"} fontSize={12} mb={1}>
											Date: {new Date(row.notified_on).toLocaleDateString(undefined, { day: "2-digit", month: "long", year: "numeric" })}
										</Typography>
									</CardContent>
								</Card>
						  ))
						: null}
				</DialogContent>
			</Dialog>

			<Dialog open={open === "checkin" ? true : false} onClose={() => setOpen("")} fullWidth maxWidth="xs">
				<DialogTitle>Daily check in</DialogTitle>
				<Divider />
				<DialogContent>
					{links.map((row, i) => (
						<Stack pb={1} pt={1} borderBottom={1} borderColor={"#F2F3F4"} key={i} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
							<Stack direction={"row"} alignItems={"center"}>
								<img src={row.icon} style={{ width: 25 }} />
								<Typography ml={1} color={"gray"} fontSize={12}>
									{row.name}
								</Typography>
							</Stack>
							<Typography fontSize={14} fontWeight={600}>
								&#8377; {row.amount} /day
							</Typography>
						</Stack>
					))}
					<Box mt={2}>
						<Button variant="contained" sx={{ width: "100%" }} disabled={action === "checking" ? true : false} onClick={() => daily_check()}>
							{action === "checking" ? <CircularProgress color="inherit" size={24} /> : "Check In"}
						</Button>
					</Box>
				</DialogContent>
			</Dialog>

			<Dialog open={open === "share" ? true : false} fullWidth maxWidth="xs" onClose={() => setOpen("")}>
				<DialogContent>
					<Box>
						<Lottie animationData={shareAnimation} style={{ width: 150, margin: "auto" }} />

						<Typography variant="h6" textAlign={"center"} fontWeight={"bold"} color={"primary.main"}>
							Earn money by sharing with <br /> YOUR FRIENDS!
						</Typography>

						<Stack direction={"column"} spacing={1} mt={1}>
							<Button
								variant="contained"
								startIcon={<WhatsApp />}
								color="success"
								onClick={() => {
									const text = encodeURIComponent(
										`✈️ *Aircraft-MFG Company* ✈️\n\n💼 *Earn Daily Revenue & Team Commissions!* 💼\n\n🌟 *Hello there!* 🌟\n\nLooking for an opportunity to boost your income effortlessly?\n\n🔓 *Join our group and unlock:*\n\n✅ *Daily Earnings* - Consistent revenue every day.\n✅ *Team Commissions* - Earn extra by growing your team.\n✅ *Start your journey to financial freedom NOW!*\n\n🔗 Click below to join:\n👉 ${window.location.hostname}/create-user?referid=${profile.userid}`
									)

									window.location.href = `https://wa.me/?text=${text}`
								}}
							>
								Share in WhatsApp
							</Button>
							<Button variant="outlined" startIcon={<CopyAll />} color="success" onClick={() => handleCopy(`${window.location.hostname}/create-user?referid=${profile.userid}`)}>
								Copy Link
							</Button>
						</Stack>
					</Box>
					<Box pt={1}>
						<Divider sx={{ color: "gray", fontSize: 12 }}>NOTICE</Divider>
						<List dense>
							<ListItem>
								<ListItemText>
									<Typography fontSize={10} color={"gray"}>
										1. Copy your exclusive invitation link or invitation code to invite your friends to join your team.
									</Typography>
								</ListItemText>
							</ListItem>
							<ListItem>
								<ListItemText>
									<Typography fontSize={10} color={"gray"}>
										2. Invite your friends to join your team and you will get team commission.
									</Typography>
								</ListItemText>
							</ListItem>
							<ListItem>
								<ListItemText>
									<Typography fontSize={10} color={"gray"}>
										3. After your members join your team, you can get the daily revenue share of all device of your team members: 5%, 3%, 2%, 1%, 0.5%, 0.1%.
									</Typography>
								</ListItemText>
							</ListItem>
						</List>
					</Box>
				</DialogContent>
			</Dialog>

			<Dialog component={"form"} open={open === "bank" ? true : false} fullWidth maxWidth="xs" fullScreen={fullscreen} onClose={() => setOpen("")}>
				<DialogTitle>Bank account details</DialogTitle>
				<Divider />
				<DialogContent>
					<Stack spacing={1.2}>
						<TextField variant="outlined" fullWidth type="text" label="Account holder name" value={name} onChange={(event) => setName(event.target.value)} />
						<TextField variant="outlined" fullWidth type="text" label="Bank name" value={bank} onChange={(event) => setBank(event.target.value)} />
						<TextField variant="outlined" fullWidth type="text" label="IFSC" value={ifsc} onChange={(event) => setIfsc(event.target.value)} />
						<TextField variant="outlined" fullWidth type="text" label="Account number" value={account} onChange={(event) => setAccount(event.target.value)} />
					</Stack>

					<Typography mt={3} fontSize={14}>
						How to?
					</Typography>
					<List dense>
						<ListItem>
							<ListItemText>1. Collect all the requited information like your bank account number, bank name, branch name, branch code.</ListItemText>
						</ListItem>
					</List>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button variant="outlined" color="inherit" onClick={() => setOpen("")}>
						Close
					</Button>
					<Button variant="contained" color="primary" type="submit" disabled={action === "addingbank" ? true : false} onClick={() => add_bank()}>
						{action === "addingbank" ? <CircularProgress color="inherit" size={24} /> : "Save"}
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog open={open === "gift" ? true : false} fullWidth maxWidth="xs" onClose={() => setOpen("")}>
				<DialogTitle>Redeem gift</DialogTitle>
				<Divider />
				<DialogContent>
					<Box>
						<TextField fullWidth variant="outlined" label="Gift code" value={giftid} onChange={(event) => setGiftid(event.target.value)} />
						<Stack direction={"row"} justifyContent={"center"} mt={2}>
							<Button variant="contained" color="primary" onClick={() => redeem_gift()} disabled={action === "redeem" ? true : false}>
								{action === "redeem" ? <CircularProgress color="inherit" size={24} /> : "Redeem Gift"}
							</Button>
						</Stack>
					</Box>
					<Box>
						<Typography color={"primary.main"} mt={1} mb={1} fontWeight={600}>
							Note:
						</Typography>
						<Typography fontSize={13} color={"gray"} mb={1}>
							1. Gift amount will be credit to your recharge wallet.
						</Typography>
						<Typography fontSize={13} color={"gray"} mb={1}>
							2. You can purchase product using this gift amount.
						</Typography>
					</Box>
				</DialogContent>
			</Dialog>

			<Dialog open={open === "level" ? true : false} fullWidth maxWidth="xs" onClose={() => setOpen("")}>
				<DialogContent>
					<Stack direction={"row"} justifyContent={"space-around"} textAlign={"center"} alignItems={"center"}>
						<Box sx={{ width: "33%", opacity: teams >= 0 ? 1 : 0.1 }}>
							<img src="/img/novice.png" style={{ width: 40 }} />
							<Typography>Novice</Typography>
						</Box>
						<Box sx={{ width: "33%", opacity: recharge >= 3000 && teams >= 10 ? 1 : 0.1 }}>
							<img src="/img/amateur.png" style={{ width: 40 }} />
							<Typography>Amateur</Typography>
						</Box>
						<Box sx={{ width: "33%", opacity: recharge >= 10000 && teams >= 50 ? 1 : 0.1 }}>
							<img src="/img/explorer.png" style={{ width: 40 }} />
							<Typography>Explorer</Typography>
						</Box>
					</Stack>
					<Stack mt={4} direction={"row"} justifyContent={"space-around"} textAlign={"center"} alignItems={"center"}>
						<Box sx={{ width: "33%", opacity: recharge >= 30000 && teams >= 100 ? 1 : 0.1 }}>
							<img src="/img/elite.png" style={{ width: 40 }} />
							<Typography>Elite</Typography>
						</Box>
						<Box sx={{ width: "33%", opacity: recharge >= 120000 && teams >= 200 ? 1 : 0.1 }}>
							<img src="/img/master.png" style={{ width: 40 }} />
							<Typography>Master</Typography>
						</Box>
						<Box sx={{ width: "33%", opacity: recharge >= 500000 && teams >= 500 ? 1 : 0.1 }}>
							<img src="/img/legend.png" style={{ width: 40 }} />
							<Typography>Legend</Typography>
						</Box>
					</Stack>
					<Stack mb={2} mt={4} direction={"row"} justifyContent={"space-around"} textAlign={"center"} alignItems={"center"}>
						<Box sx={{ width: "33%", opacity: recharge >= 800000 && teams >= 1500 ? 1 : 0.1 }}>
							<img src="/img/demigod.png" style={{ width: 40 }} />
							<Typography>Demigod</Typography>
						</Box>
						<Box sx={{ width: "33%", opacity: recharge >= 1500000 && teams >= 3000 ? 1 : 0.1 }}>
							<img src="/img/assistant.png" style={{ width: 40 }} />
							<Typography>Assistant</Typography>
						</Box>
						<Box sx={{ width: "33%", opacity: recharge >= 2000000 && teams >= 5000 ? 1 : 0.1 }}>
							<img src="/img/manager.png" style={{ width: 40 }} />
							<Typography>Manager</Typography>
						</Box>
					</Stack>
					<Divider />

					<Typography mt={2} color={"primary.main"} fontWeight={500} fontSize={12}>
						1. You are in Level {team.level}
					</Typography>
					<Typography mt={0.5} color={"primary.main"} fontWeight={500} fontSize={12}>
						2.
						{team.level === "Novice"
							? ` You need ${teams > 10 ? 0 : 10 - teams} team size and ${recharge > 3000 ? 0 : 3000 - recharge} cumulative recharge for Amateur level.`
							: team.level === "Amateur"
							? ` You need ${teams > 50 ? 0 : 50 - teams} team size and ${recharge > 10000 ? 0 : 10000 - recharge} cumulative recharge for Explorer level.`
							: team.level === "Explorer"
							? ` You need ${teams > 100 ? 0 : 100 - teams} team size and ${recharge > 30000 ? 0 : 30000 - recharge} cumulative recharge for Elite level.`
							: team.level === "Elite"
							? ` You need ${teams > 200 ? 0 : 200 - teams} team size and ${recharge > 120000 ? 0 : 120000 - recharge} cumulative recharge for Master level.`
							: team.level === "Master"
							? ` You need ${teams > 500 ? 0 : 500 - teams} team size and ${recharge > 500000 ? 0 : 500000 - recharge} cumulative recharge for Legend level.`
							: team.level === "Legend"
							? ` You need ${teams > 1500 ? 0 : 1500 - teams} team size and ${recharge > 800000 ? 0 : 800000 - recharge} cumulative recharge for Demigod level.`
							: team.level === "Demigod"
							? ` You need ${teams > 3000 ? 0 : 3000 - teams} team size and ${recharge > 1500000 ? 0 : 1500000 - recharge} cumulative recharge for Assistant level.`
							: team.level === "Assistant"
							? ` You need ${teams > 5000 ? 0 : 5000 - teams} team size and ${recharge > 2000000 ? 0 : 2000000 - recharge} cumulative recharge for Assistant level.`
							: " Hurry! You reached all level."}
					</Typography>
				</DialogContent>
			</Dialog>

			<Container sx={{ mb: 1 }}>
				<Card>
					<CardContent style={{ paddingBottom: 10 }}>
						<Stack mb={1} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
							<Stack sx={{ width: "80%", overflow: "hidden" }} direction={"row"} spacing={2} alignItems={"center"} onClick={() => setOpen("level")}>
								<img src={`/img/${levelicon}.png`} style={{ width: "50px", height: "50px" }} />
								<Box sx={{ width: "100%" }} className="ellipsis">
									<Typography fontSize={14} noWrap fontWeight={600} color={"primary.main"}>
										{profile.name}
									</Typography>
									<Typography fontSize={10} color={"secondary.main"} fontWeight={500}>
										User ID: {profile.userid}
									</Typography>
								</Box>
							</Stack>
							<Box sx={{ background: "#F5F5F5", width: "60px", padding: "0.5em 1em 0.5em 1em" }} borderRadius={1.5} textAlign={"center"}>
								<Typography fontSize={12} fontWeight={600} letterSpacing={2}>
									{(parseInt(wallet.recharge) / 10).toFixed()}
								</Typography>
								<Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
									<Diamond color="primary" style={{ fontSize: 12 }} />
									<Typography fontSize={12} mt={-0.4}>
										score
									</Typography>
								</Stack>
							</Box>
						</Stack>
						<Divider />
						<Box mt={1} textAlign={"center"}>
							<Typography variant="caption" textAlign={"center"} color={"primary.main"} fontWeight={"medium"}>
								You are in <u style={{ fontWeight: "bold" }}>{team.level}</u> level
							</Typography>
						</Box>
					</CardContent>
				</Card>
			</Container>

			<Container>
				<BalanceUser />
				<Card sx={{ mt: 1 }}>
					<CardContent>
						<Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
							<Button variant="outlined" startIcon={<CallMissedOutgoing />} size="small" color="secondary" sx={{ width: "100%" }} onClick={() => navi("/user/withdraw")}>
								Withdraw
							</Button>
							<Button variant="contained" startIcon={<AccountBalanceWallet />} size="small" color="primary" sx={{ width: "100%" }} onClick={() => navi("/user/recharge")}>
								Recharge
							</Button>
						</Stack>
					</CardContent>
				</Card>

				<Card sx={{ mt: 1 }}>
					<CardContent>
						<Grid container spacing={2}>
							<Grid item xs={3}>
								<Box textAlign={"center"}>
									<IconButton onClick={() => navi("/user/my-products")}>
										<RocketLaunch sx={{ fontSize: 20 }} />
									</IconButton>
									<Typography fontSize={10} fontWeight={500}>
										My Engine
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={3}>
								<Box textAlign={"center"}>
									<IconButton onClick={() => navi("/user/team")}>
										<Groups sx={{ fontSize: 20 }} />
									</IconButton>
									<Typography fontSize={10} fontWeight={500}>
										My Team
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={3}>
								<Box textAlign={"center"}>
									<IconButton onClick={() => setOpen("share")}>
										<Share sx={{ fontSize: 20 }} />
									</IconButton>
									<Typography fontSize={10} fontWeight={500}>
										Invite friends
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={3}>
								<Box textAlign={"center"}>
									<IconButton onClick={() => setOpen("checkin")}>
										<Task sx={{ fontSize: 20 }} />
									</IconButton>
									<Typography fontSize={10} fontWeight={500}>
										Check in
									</Typography>
								</Box>
							</Grid>
						</Grid>

						<Grid container spacing={2} mt={1}>
							<Grid item xs={3}>
								<Box textAlign={"center"}>
									<IconButton onClick={() => navi("/user/salary")}>
										<Receipt sx={{ fontSize: 20 }} />
									</IconButton>
									<Typography fontSize={10} fontWeight={500}>
										Salary
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={3}>
								<Box textAlign={"center"}>
									<IconButton onClick={() => setOpen("gift")}>
										<Redeem sx={{ fontSize: 20 }} />
									</IconButton>
									<Typography fontSize={10} fontWeight={500}>
										Gift
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={3}>
								<Box textAlign={"center"}>
									<IconButton onClick={() => setOpen("bank")}>
										<AccountBalance sx={{ fontSize: 20 }} />
									</IconButton>
									<Typography fontSize={10} fontWeight={500}>
										Bank Account
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={3}>
								<Box textAlign={"center"}>
									<IconButton onClick={() => navi("/user/rank")}>
										<MilitaryTech sx={{ fontSize: 20 }} />
									</IconButton>
									<Typography fontSize={10} fontWeight={500}>
										Rank List
									</Typography>
								</Box>
							</Grid>
						</Grid>
					</CardContent>
				</Card>

				<Box component={Paper} mt={2}>
					<List dense>
						<ListItem>
							<ListItemButton onClick={() => navi("/user/support")}>
								<ListItemIcon>
									<SupportAgent />
								</ListItemIcon>
								<ListItemText>Support</ListItemText>
							</ListItemButton>
						</ListItem>
						<Divider />
						<ListItem>
							<a href="/user/light-app" style={{ color: "black" }}>
								<ListItemButton>
									<ListItemIcon>
										<GetApp />
									</ListItemIcon>
									<ListItemText>Download app</ListItemText>
								</ListItemButton>
							</a>
						</ListItem>
						<Divider />
						<ListItem>
							<ListItemButton onClick={() => navi("/user/team-rules")}>
								<ListItemIcon>
									<StickyNote2 />
								</ListItemIcon>
								<ListItemText>Rule</ListItemText>
							</ListItemButton>
						</ListItem>
						<Divider />
						<ListItem>
							<ListItemButton onClick={() => navi("/user/faq")}>
								<ListItemIcon>
									<Quiz />
								</ListItemIcon>
								<ListItemText>FAQ</ListItemText>
							</ListItemButton>
						</ListItem>
					</List>
				</Box>
			</Container>

			<Box textAlign={"center"} mt={3}>
				<Typography fontSize={12} color={"gray"} onClick={() => navi("/term_condition")}>
					Terms & Conditions
				</Typography>
			</Box>
		</Box>
	)
}

import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { Box, Button, Card, CardContent, Container, Divider, Typography } from "@mui/material"
import axios from "axios"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

const PaymentReceipt = () => {
	let navigate = useNavigate()
	const apiUrl = process.env.REACT_APP_API
	const receiptRef = useRef(null)

	const handlePrint = () => {
		const receiptElement = document.getElementById("receipt")

		html2canvas(receiptElement).then((canvas) => {
			const imgData = canvas.toDataURL("image/png")
			const pdf = new jsPDF()
			const imgWidth = 190
			const imgHeight = (canvas.height * imgWidth) / canvas.width
			pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight)
			pdf.save("payment-receipt.pdf")
		})
	}

	const [jobform, setJobForm] = useState({})

	useEffect(() => {
		getJobForm()
	}, [])

	const getJobForm = async () => {
		try {
			const response = await axios.get(`${apiUrl}/user?job-form-details`)
			if (response.data.status === true) {
				setJobForm(response.data.data)
			} else {
				setJobForm(response.data)
			}
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<Box className="content" pb={10}>
			<Container>
				<Card sx={{ maxWidth: 400, margin: "auto", mt: 5, p: 3, boxShadow: 3, borderRadius: 2 }}>
					<div id="receipt" ref={receiptRef}>
						<Box textAlign="center" mb={2}>
							<CheckCircleIcon color="success" sx={{ fontSize: 40 }} />
							<Typography variant="h6" fontWeight="bold">
								Payment Successful
							</Typography>
							<Typography variant="body2" color="text.secondary">
								You have successfully paid for your job application
							</Typography>
						</Box>
						<CardContent>
							<Typography variant="body2">
								<Typography component="span" fontWeight={"bold"}>
									Job Title:
								</Typography>{" "}
								Data Entry & Processing - Assistant Manager
							</Typography>
							<Typography mt={1} variant="body2" fontWeight={"bold"}>
								Application Fee ₹ 4,999
							</Typography>
							<Divider sx={{ my: 2 }} />
							<Typography variant="body2">
								<strong>Date:</strong> {jobform.created_at || "N/A"}
							</Typography>
							<Typography variant="body2">
								<strong>Payment Method:</strong> Wallet
							</Typography>
							<Typography variant="body2">
								<strong>Applicant:</strong> {jobform.name || "N/A"}
							</Typography>
						</CardContent>
					</div>
					<Button variant="contained" color="primary" onClick={handlePrint} fullWidth sx={{ mt: 2 }}>
						Download Receipt
					</Button>
					<Button onClick={() => navigate(-1)} variant="outlined" fullWidth sx={{ mt: 1 }}>
						Back to Dashboard
					</Button>
				</Card>
			</Container>
		</Box>
	)
}

export default PaymentReceipt

import { Box, Container, Dialog, DialogContent } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"

import "plyr-react/plyr.css"

import { Button, Grid, MenuItem, Paper, TextField, Typography } from "@mui/material"
import axios from "axios"

import { addMonths, format, subDays } from "date-fns"
import Lottie from "lottie-react"
import { useNavigate } from "react-router-dom"
import ReviewAnimation from "../../lottie/review.json"
import SuccessAnimation from "../../lottie/success.json"

import { Pause, PlayArrow } from "@mui/icons-material"
import { IconButton } from "@mui/material"

import { useReactToPrint } from "react-to-print"

const JobForm = ({ snackbar }) => {
	const apiUrl = process.env.REACT_APP_API
	let navigate = useNavigate()
	const [instructions, setInstructions] = useState(true)
	const [action, setAction] = useState("")

	const [isPlaying, setIsPlaying] = useState(false)
	const audioRef = useRef(null)

	const [formData, setFormData] = useState({
		fullName: "",
		dateOfBirth: "",
		gender: "",
		contactNumber: "",
		email: "",
		address: "",
		degree: "",
		institution: "",
		passingYear: "",
		marks: "",
		aadhaarCard: null,
		panCard: null,
		marksheet: null,
	})

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value })
	}

	const handleFileChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.files[0] })
	}

	const handleSubmit = async (e) => {
		e.preventDefault()

		try {
			setAction("submit")
			if (!formData.aadhaarCard || !formData.marksheet) {
				snackbar("Please upload all required documents.")
				return
			}

			const form = new FormData()
			form.append("apply-job-form", "")
			form.append("name", formData.fullName)
			form.append("birth_date", formData.dateOfBirth)
			form.append("gender", formData.gender)
			form.append("contact", formData.contactNumber)
			form.append("email", formData.email)
			form.append("address", formData.address)
			form.append("degree", formData.degree)
			form.append("institution", formData.institution)
			form.append("passingYear", formData.passingYear)
			form.append("marks", formData.marks)
			form.append("aadhaarCard", formData.aadhaarCard)
			form.append("marksheet", formData.marksheet)

			const response = await axios.post(`${apiUrl}/user`, form, {
				onUploadProgress: (progressEvent) => {
					const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
					console.log(`Upload Progress: ${percentCompleted}%`)
					snackbar(`Uploading: ${percentCompleted}%`)
				},
			})

			if (response.data.status === true) {
				snackbar(response.data.msg)
				getJobForm()
			} else {
				snackbar(response.data.msg)
			}
		} catch (error) {
			console.log(error)
		} finally {
			setAction("")
		}
	}

	const [jobform, setJobForm] = useState("")

	useEffect(() => {
		getJobForm()
	}, [])

	const getJobForm = async () => {
		try {
			const response = await axios.get(`${apiUrl}/user?job-form-details`)
			if (response.data.status === true) {
				setJobForm(response.data.data)
			} else {
				setJobForm(response.data)
			}
		} catch (error) {
			console.log(error)
		}
	}

	const togglePlay = () => {
		if (audioRef.current) {
			if (isPlaying) {
				audioRef.current.pause()
			} else {
				audioRef.current.play()
			}
			setIsPlaying(!isPlaying)
		}
	}

	const receiptRef = useRef()

	const handlePrint = useReactToPrint({
		content: () => receiptRef.current,
	})

	return (
		<Box className="content" pb={10}>
			<Dialog open={instructions} fullWidth maxWidth="xs" onClose={() => setInstructions(false)}>
				<DialogContent>
					<Box>
						<Typography variant="h6" fontWeight={"bold"} gutterBottom>
							Welcome to Aircraft-MFG
						</Typography>
						<Typography variant="body2" fontWeight={"bold"} component={"div"}>
							Before you apply for this job, please see the instructions below.
						</Typography>
						<Typography mt={2} variant="caption" component={"div"} color="gray">
							1. Please listen the audio. It contains the job details.
						</Typography>
						<Typography variant="caption" component={"div"} color="gray">
							2. Fill the form with correct details and upload the required documents.
						</Typography>
						<Typography variant="caption" component={"div"} color="gray">
							3. To fill this job form you have to pay a fee of &nbsp;
							<Typography variant="span" color={"red"} fontWeight={"bold"}>
								Rs. 4,999/-
							</Typography>
							.
						</Typography>
						<Typography variant="caption" component={"div"} color="gray">
							4. Amount will be deducted from your wallet after submitting the form.
						</Typography>
						<Typography variant="caption" component={"div"} color="gray">
							5. Remember, the fee is non-refundable.
						</Typography>
						<Typography variant="caption" component={"div"} color="gray">
							6. Total sits for this job are:{" "}
							<Typography variant="span" fontWeight={"bold"} color={"black"}>
								{" "}
								500
							</Typography>
						</Typography>

						<Typography mt={2} variant="body2" fontWeight={"bold"} component={"div"}>
							Joining Details:
						</Typography>
						<Typography variant="caption" component={"div"} color="gray">
							1. Work Type: Work from home.
						</Typography>
						<Typography variant="caption" component={"div"} color="gray">
							2. Your joining date will be notified via email.
						</Typography>
						<Typography variant="caption" component={"div"} color="gray">
							3. You will receive your offer letter after 3 months of your application.
						</Typography>
						<Typography variant="caption" component={"div"} color="gray">
							4. You can join the company after 3 months of your application.
						</Typography>
					</Box>
					<Box mt={2}>
						<Button variant="contained" color="primary" fullWidth onClick={() => setInstructions(false)}>
							Okay, I Understand
						</Button>
					</Box>
				</DialogContent>
			</Dialog>
			<Container>
				<Box component={Paper} display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="10vh" p={4}>
					<Typography variant="body1" fontWeight={"bold"} gutterBottom>
						Play Audio Instructions
					</Typography>

					{/* Audio Player */}
					<audio ref={audioRef} src="/audio/DataEntry_Voice.mp3" />

					{/* Play/Pause Button */}
					<IconButton onClick={togglePlay} color="primary" size="large">
						{isPlaying ? <Pause fontSize="large" /> : <PlayArrow fontSize="large" />}
					</IconButton>

					{/* Visualizer GIF (Shown Only When Playing) */}
					{isPlaying && (
						<Box mt={2}>
							<img src="/img/visualizer.gif" alt="Audio Visualizer" width={250} style={{ borderRadius: "10px" }} />
						</Box>
					)}
				</Box>

				{jobform.status === "declined" ? (
					<Box mt={3}>
						<Paper elevation={1} sx={{ px: 2, py: 3 }}>
							<Typography color={"error"} variant="h6" fontWeight={"bold"} gutterBottom>
								Application Declined
							</Typography>
							<Typography variant="caption" component={"div"} color={"gray"}>
								1. We regret to inform you that your job application for the position of Assistant Manager at Aircraft-MFG has been declined.
							</Typography>
							<Typography mt={1} variant="caption" component={"div"} color={"gray"}>
								2. Please re-submit your application. Thank you for your interest in Aircraft-MFG.
							</Typography>
						</Paper>
					</Box>
				) : null}

				{jobform.status === false || jobform.status === "declined" ? (
					<Box mt={2}>
						<Paper elevation={1} sx={{ px: 2, py: 3 }}>
							<Typography variant="h6" gutterBottom fontWeight={"bold"} mb={2}>
								Job Application Form
							</Typography>
							<form onSubmit={handleSubmit}>
								<Grid container spacing={2}>
									{/* Personal Information */}
									<Grid item xs={12}>
										<TextField fullWidth label="Full Name" name="fullName" value={formData.fullName} onChange={handleChange} requiredd />
									</Grid>
									<Grid item xs={12}>
										<TextField fullWidth type="date" label="Date of Birth" name="dateOfBirth" InputLabelProps={{ shrink: true }} value={formData.dateOfBirth} onChange={handleChange} requiredd />
									</Grid>
									<Grid item xs={12}>
										<TextField select fullWidth label="Gender" name="gender" value={formData.gender} onChange={handleChange} requiredd>
											<MenuItem value="Male">Male</MenuItem>
											<MenuItem value="Female">Female</MenuItem>
											<MenuItem value="Other">Other</MenuItem>
										</TextField>
									</Grid>
									<Grid item xs={12}>
										<TextField type="number" fullWidth label="Contact Number" name="contactNumber" value={formData.contactNumber} onChange={handleChange} requiredd />
									</Grid>
									<Grid item xs={12}>
										<TextField fullWidth label="Email" type="email" name="email" value={formData.email} onChange={handleChange} requiredd />
									</Grid>
									<Grid item xs={12}>
										<TextField fullWidth label="Address" name="address" multiline rows={3} value={formData.address} onChange={handleChange} requiredd />
									</Grid>
								</Grid>

								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Typography mt={3} variant="h6" gutterBottom fontWeight={"bold"}>
											Education Qualification
										</Typography>
									</Grid>
									{/* Educational Qualification */}
									<Grid item xs={12}>
										<TextField fullWidth label="Highest Degree" name="degree" value={formData.degree} onChange={handleChange} requiredd />
									</Grid>
									<Grid item xs={12}>
										<TextField fullWidth label="Institution Name" name="institution" value={formData.institution} onChange={handleChange} requiredd />
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth type="number" label="Year of Passing" name="passingYear" value={formData.passingYear} onChange={handleChange} requiredd />
									</Grid>
									<Grid item xs={6}>
										<TextField fullWidth type="number" label="Marks/Percentage" name="marks" value={formData.marks} onChange={handleChange} requiredd />
									</Grid>
								</Grid>

								<Grid container spacing={1}>
									{/* Document Upload */}
									<Grid item xs={12} mt={3}>
										<Typography variant="subtitle1">Upload Documents:</Typography>
									</Grid>
									<Grid item xs={12}>
										<Button disabled={!formData.aadhaarCard ? false : true} variant="contained" component="label" fullWidth>
											Aadhaar Card*
											<input requiredd type="file" name="aadhaarCard" hidden onChange={handleFileChange} />
										</Button>
									</Grid>
									<Grid item xs={12}>
										<Button requiredd disabled={!formData.marksheet ? false : true} variant="contained" component="label" fullWidth>
											Marksheet*
											<input type="file" name="marksheet" hidden onChange={handleFileChange} />
										</Button>
									</Grid>

									{/* Submit Button */}
									<Grid item xs={12}>
										<Button disabled={action === "submit" ? true : false} type="submit" variant="contained" color="primary" fullWidth>
											Submit Application
										</Button>
									</Grid>
								</Grid>
							</form>
						</Paper>
					</Box>
				) : jobform.status === "review" ? (
					<Box px={3} py={2} mt={2} component={Paper} elevation={1} borderRadius={2}>
						<Lottie animationData={ReviewAnimation} style={{ width: 200, margin: "auto" }} />

						<Box mt={3}>
							<Typography variant="body1" fontWeight={"bold"} color={"warning.main"} mb={2}>
								We are reviewing your application. Please bear with us.
							</Typography>
							<Typography variant="body2" color={"gray"} mt={1}>
								Dear {jobform.name} , your job application is currently under review. We will notify you soon regarding the next steps. Thank you for your patience!
							</Typography>

							<Box mt={2}>
								<Button variant="contained" color="primary" fullWidth onClick={() => navigate("/user/payment-receipt")}>
									Download Payment Receipt
								</Button>
								<Button sx={{ mt: 1 }} variant="outlined" color="primary" fullWidth onClick={() => navigate(-2)}>
									Go back
								</Button>
							</Box>
						</Box>
					</Box>
				) : (
					jobform.status === "active" && (
						<Box px={2} py={2} mt={2} component={Paper} elevation={1} borderRadius={2}>
							<Lottie animationData={SuccessAnimation} style={{ width: 200, margin: "auto" }} />

							<Box mt={3}>
								<Typography variant="body1" fontWeight={"bold"} color={"green"} mb={2}>
									Congratulations! Your Job Offer & Joining Details
								</Typography>
								<Box>
									<Typography mb={1} variant="body1" fontWeight={"bold"}>
										Dear {jobform.name},
									</Typography>
									<Typography variant="body2" color={"gray"}>
										Congratulations! We are pleased to inform you that you have been selected for the position of Assistant Manager at Aircraft-MFG.
									</Typography>
									<Typography variant="body1" color={"gray"} mt={1}>
										Below are your joining details:
									</Typography>

									<Typography variant="body2" fontWeight={"bold"} mt={1}>
										Offer Letter & Documentation:
									</Typography>

									<Typography variant="body2" mt={1} color={"gray"}>
										1. You will receive your official Offer Letter on {format(subDays(addMonths(new Date(jobform.created_at), 4), 7), "dd-MM-yyyy")} via email.
									</Typography>

									<Typography variant="body2" mt={1} color={"gray"}>
										1. Your joining date is {format(subDays(addMonths(new Date(jobform.created_at), 4), 7), "dd-MM-yyyy")}.
									</Typography>
									<Typography variant="body2" mt={1} color={"gray"}>
										2. Please review, sign, and submit the required documents by {format(subDays(addMonths(new Date(jobform.created_at), 4), 2), "dd-MM-yyyy")}.
									</Typography>
									<Typography variant="body2" mt={1} color={"gray"}>
										3. Work type: Work from home.
									</Typography>

									<Typography variant="body2" color={"gray"} mt={1}>
										If you have any questions, feel free to reach out to aircraftmfgmmc@gmail.com. <br /> We look forward to welcoming you to our team!
									</Typography>

									<Box mt={2}>
										<Button variant="contained" color="primary" fullWidth onClick={() => navigate("/user/payment-receipt")}>
											Download Payment Receipt
										</Button>
										<Button sx={{ mt: 1 }} variant="outlined" color="primary" fullWidth onClick={() => navigate(-2)}>
											Go back
										</Button>
									</Box>
								</Box>
							</Box>
						</Box>
					)
				)}
			</Container>
		</Box>
	)
}

export default JobForm

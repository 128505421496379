import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import MyContext from "../../context/context"

// MUI
import { Attachment, Edit, Search } from "@mui/icons-material"
import { Box, Chip, Container, Dialog, DialogContent, FormControl, IconButton, Input, InputAdornment, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import axios from "axios"

import InfiniteScroll from "react-infinite-scroll-component"

export default function JobApplication({ snackbar }) {
	const api = useContext(MyContext)
	const [open, setOpen] = useState("")
	const [action, setAction] = useState("")
	const navi = useNavigate()

	var url = new URLSearchParams(window.location.search).get("value")
	const [searchquery, setSearchquery] = useState(url ? url : "")

	const [users, setUsers] = useState([])
	const [hasMore, setHasMore] = useState(true)
	const [page, setPage] = useState(0)
	useEffect(() => {
		get_users(page)
	}, [page])
	const get_users = (page) => {
		axios.get(`${api.api.admin}?job-application=${searchquery}`).then(function (res) {
			if (page > 0) {
				if (res.data.status === true) {
					const newdata = res.data.data
					setUsers((pre) => [...pre, ...newdata])
				} else {
					setHasMore(false)
				}
			} else if (res.data.status === true) {
				setUsers(res.data.data)
			} else {
				setUsers(res.data)
			}
		})
	}

	const loadmore = () => {
		setPage((page) => page + 1)
	}

	const search_user = (event) => {
		if (event.key === "Enter") {
			navi(`?value=${event.target.value}`, { replace: true })
			get_users(0)
		}
	}

	const [user, setUser] = useState([])
	const get_user_details = (userid) => {
		axios.get(`${api.api.admin}?job-application-details=${userid}`).then(function (res) {
			if (res.data.status === true) {
				setUser(res.data.data)
				setOpen("contstatus")
			}
		})
	}

	const update_cont_status = (status) => {
		const formData = new FormData()
		formData.append("update-job-application", user.userid)
		formData.append("status", status)
		axios.post(api.api.admin, formData).then(function (res) {
			snackbar(res.data.msg)
			if (res.data.status === true) {
				get_user_details(user.userid)
				get_users(page)
			}
		})
	}

	return (
		<Box className="content" mb={{ xs: 10, md: 0 }}>
			<Container>
				<Dialog open={open === "contstatus" ? true : false} maxWidth="xs" onClose={() => setOpen("")}>
					<DialogContent>
						<Box p={2}>
							<Box p={4}>
								<img src="/img/salary.svg" alt="Salary" />
							</Box>
							<Typography mb={1} color={"primary.main"} fontWeight={"bold"} variant="h6">
								Job applicatoin status
							</Typography>
							<FormControl fullWidth size="small">
								<InputLabel id="demo-simple-select-label">Contract status</InputLabel>
								<Select labelId="demo-simple-select-label" id="demo-simple-select" value={user.status} label="Contract status" onChange={(event) => update_cont_status(event.target.value)}>
									<MenuItem value={"active"}>Active</MenuItem>
									<MenuItem value={"review"}>In review</MenuItem>
									<MenuItem value={"declined"}>Declined</MenuItem>
								</Select>
							</FormControl>
							<Typography color={"gray"} fontSize={12}>
								Select to change status
							</Typography>
						</Box>
					</DialogContent>
				</Dialog>

				<FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
					<InputLabel htmlFor="outlined-adornment-password">Search user</InputLabel>
					<Input
						id="outlined-adornment-password"
						type="text"
						placeholder="Search by user ID, name, mobile..."
						endAdornment={
							<InputAdornment position="end">
								<IconButton aria-label="toggle password visibility" edge="end" onClick={() => search_user()}>
									<Search />
								</IconButton>
							</InputAdornment>
						}
						label="Search user"
						value={searchquery}
						onChange={(event) => setSearchquery(event.target.value)}
						onKeyUp={(event) => search_user(event)}
					/>
				</FormControl>

				<TableContainer component={Paper} sx={{ mt: 2 }}>
					<InfiniteScroll dataLength={users.length} next={loadmore} hasMore={hasMore} loader={<h4>Loading...</h4>} endMessage={<p style={{ textAlign: "center" }}>No more data to load</p>}>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>#</TableCell>
									<TableCell align="left">User ID</TableCell>
									<TableCell align="left">Name</TableCell>
									<TableCell align="left">Contract date</TableCell>
									<TableCell align="left">Contract status</TableCell>
									<TableCell align="left">Document</TableCell>
									<TableCell align="right">Action</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{users.status !== false &&
									users.map((row, i) => (
										<TableRow key={i}>
											<TableCell align="left">{i + 1}</TableCell>
											<TableCell align="left">{row.userid}</TableCell>
											<TableCell align="left">{row.name}</TableCell>
											<TableCell align="left">{new Date(row.created_at).toLocaleDateString(undefined, { day: "2-digit", month: "2-digit", year: "numeric" })}</TableCell>
											<TableCell align="left">
												<Chip variant="outlined" color={row.status === "active" ? "primary" : row.status === "review" ? "warning" : row.status === "declined" ? "error" : "default"} label={row.status} sx={{ textTransform: "capitalize" }} />
											</TableCell>
											<TableCell align="left">
												<Stack direction={{ xs: "column", md: "row" }} spacing={1}>
													<Chip clickable icon={<Attachment />} label="Aadhaar" color="primary" onClick={() => window.open(`/aircraftassets/img/user/${row.aadhaar}`, "_blank")} />
													<Chip clickable icon={<Attachment />} label="Marksheet" color="primary" onClick={() => window.open(`/aircraftassets/img/user/${row.marksheet}`, "_blank")} />
												</Stack>
											</TableCell>
											<TableCell align="right">
												<Chip variant="contained" color="primary" label="Edit" clickable icon={<Edit />} onClick={() => get_user_details(row.userid)} />
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
					</InfiniteScroll>
				</TableContainer>
			</Container>
		</Box>
	)
}

import { Box, Button, Container, Typography } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"

export default function Job() {
	let navigate = useNavigate()
	return (
		<Box className="content" pb={10}>
			<Container>
				<Box textAlign={"center"} mt={2}>
					<img src="/img/job.png" style={{ width: "300px" }} />
				</Box>
				<Box>
					<Typography mt={2} variant="h6" fontWeight={"bold"}>
						Job Title
					</Typography>
					<Typography variant="body2" fontWeight={"bold"}>
						Assistant Mananger - Data Entry & Processing
					</Typography>

					<Typography mt={2} variant="body1" fontWeight={"bold"} mb={1}>
						Job Summary
					</Typography>
					<Typography lineHeight={1.6} variant="body2" color={"gray"}>
						We are seeking a skilled and detail-oriented Financial Analyst to join our team. This role is crucial for driving data-driven financial decisions by analyzing financial data, preparing accurate reports, and providing strategic recommendations. The ideal candidate will play a pivotal role in budgeting, forecasting, and financial planning while ensuring compliance with organizational and regulatory standards.
					</Typography>

					<Typography mt={2} variant="body1" fontWeight={"bold"} mb={1}>
						Key Responsibilities
					</Typography>
					<Typography lineHeight={1.6} variant="body2" color={"gray"}>
						1. Analyze financial data to identify trends, variances, and opportunities for cost optimization.
						<br />
						2. Prepare, review, and present financial reports to senior management.
						<br />
						3. Assist in budgeting and forecasting processes, ensuring accuracy and timeliness.
						<br />
						4. Conduct market and industry research to support strategic decision-making.
						<br />
						5. Monitor financial performance by tracking key performance indicators (KPIs).
						<br />
						6. Collaborate with various departments to gather and interpret financial data.
						<br />
						7.Ensure compliance with financial regulations, company policies, and best practices.
						<br />
						8. Provide support for internal and external audits.
						<br />
						9. Develop financial models and perform scenario analysis to support business initiatives.
					</Typography>

					<Typography mt={2} variant="body1" fontWeight={"bold"} mb={1}>
						Work Environment
					</Typography>
					<Typography lineHeight={1.6} variant="body2" color={"gray"}>
						This position is remote-site job opportunities for hybrid work arrangements. Occasional travel may be required for meetings or training.
					</Typography>

					<Typography mt={2} variant="body1" fontWeight={"bold"} mb={1}>
						Compensation and Benefits
					</Typography>
					<Typography lineHeight={1.6} variant="body2" color={"gray"}>
						We offer a competitive salary and benefits package, including:
						<br />
						1. Health, dental, and vision insurance.
						<br />
						2. Retirement plan with company matching.
						<br />
						3. Paid time off, including holidays and vacation days.
						<br />
						4. Opportunities for professional development and certification reimbursement.
					</Typography>

					<Typography mt={2} variant="body1" fontWeight={"bold"} mb={1}>
						How to Apply
					</Typography>
					<Typography lineHeight={1.6} variant="body2" color={"gray"}>
						To apply, please submit form details by{" "}
						<Typography variant="span" fontWeight={"bold"} color={"red"}>
							{" "}
							16th February 2025.
						</Typography>
					</Typography>
				</Box>

				<Box mt={3}>
					<Button variant="contained" color="primary" size="large" fullWidth onClick={() => navigate("/user/job-form")}>
						Apply Now
					</Button>
				</Box>
			</Container>
		</Box>
	)
}

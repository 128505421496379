import Cookies from "js-cookie"
import React, { useContext, useEffect, useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import MyContext from "../context/context"

// MUI
import { BuildCircle, CircleNotifications, Face, Gavel, Menu, MilitaryTech, Notifications, People, PowerSettingsNew, Receipt, Redeem, RocketLaunch, Upcoming, Work } from "@mui/icons-material"
import { AppBar, Badge, Box, Button, CircularProgress, Dialog, DialogContent, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Toolbar, Typography } from "@mui/material"
import axios from "axios"

export default function SidebarAdmin({ snackbar }) {
	const navi = useNavigate()
	const api = useContext(MyContext)
	const auth = Cookies.get("authuser")
	const [open, setOpen] = useState("")
	const [action, setAction] = useState("")
	const notifications = Cookies.get("notifications")
	let location = useLocation()

	useEffect(() => {
		auth !== "admin" && navi("/login-admin")
	}, [])
	const link = [
		{ name: "Home", link: "/admin/home", icon: <RocketLaunch /> },
		{ name: "Engine", link: "/admin/device", icon: <BuildCircle /> },
		{ name: "User", link: "/admin/user", icon: <Face /> },
		{ name: "Request", link: "/admin/withdraw-request", icon: <CircleNotifications /> },
		{ name: "Salary", link: "/admin/salary", icon: <Upcoming /> },
		{ name: "Application", link: "/admin/job-application", icon: <Work /> },
		{ name: "Gift", link: "/admin/gift", icon: <Redeem /> },
		{ name: "Rules", link: "/admin/rules", icon: <Gavel /> },
		{ name: "Transaction", link: "/admin/transactions", icon: <Receipt /> },
		{ name: "Ranking", link: "/admin/ranking", icon: <MilitaryTech /> },
		{ name: "Community", link: "/admin/community", icon: <People /> },
	]

	const logout = () => {
		setAction("loggingout")
		var formData = new FormData()
		formData.append("logout", "")
		axios.post(api.api.admin, formData).then(function (res) {
			setAction("")
			snackbar(res.data.msg)
			res.data.status === true && navi("/login-admin", { replace: true })
		})
	}

	return (
		<>
			<AppBar position="sticky">
				<Toolbar>
					<Typography fontSize={20} className="font-anta">
						Aircraft-MFG
					</Typography>
					<Typography sx={{ flexGrow: 1 }} />
					<IconButton color="inherit" onClick={() => navi("/admin/notification")}>
						<Badge color="error" badgeContent={notifications}>
							<Notifications />
						</Badge>
					</IconButton>
					<IconButton color="inherit" onClick={() => setOpen("logout")}>
						<PowerSettingsNew />
					</IconButton>
					<IconButton color="inherit" onClick={() => setOpen("opendrawer")} sx={{ display: { xs: "inline-flex", md: "none" } }}>
						<Menu />
					</IconButton>
				</Toolbar>
			</AppBar>

			<Dialog open={open === "logout" ? true : false} fullWidth maxWidth="xs" onClose={() => setOpen("")}>
				<DialogContent>
					<Box textAlign={"center"}>
						<Typography color={"primary.main"} fontWeight={600} fontSize={33}>
							Do you want to logout?
						</Typography>
						<Typography fontSize={12} color={"gray"}>
							*This process will redirect you to the login page
						</Typography>
						<Typography fontSize={12} color={"gray"} mb={2}>
							*You can't undo this process
						</Typography>

						<Button variant="contained" color="primary" disabled={action === "loggingout" ? true : false} onClick={() => logout()}>
							{action === "loggingout" ? <CircularProgress color="inherit" size={24} /> : "Logout Now"}
						</Button>
					</Box>
				</DialogContent>
			</Dialog>

			<Drawer
				variant="permanent"
				sx={{
					display: { xs: "none", sm: "block" },
				}}
				PaperProps={{ style: { width: "256px" } }}
			>
				<Toolbar>
					<Stack mx={"auto"} direction={"row"} alignItems={"center"} justifyContent={"center"}>
						<Typography textAlign={"center"} color={"primary"} fontSize={25} mr={1} className="font-anta">
							Aircraft-MFG
						</Typography>
						<RocketLaunch color="primary" />
					</Stack>
				</Toolbar>
				<Divider />
				<List>
					{link.map((row, i) => (
						<ListItem key={i}>
							<ListItemButton onClick={() => navi(row.link)} sx={location.pathname === row.link ? { backgroundColor: "primary.main", color: "white", borderRadius: 2 } : null}>
								<ListItemIcon sx={location.pathname === row.link ? { color: "white" } : null}>{row.icon}</ListItemIcon>
								<ListItemText>{row.name}</ListItemText>
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</Drawer>

			<Drawer open={open === "opendrawer" ? true : false} onClose={() => setOpen("")} PaperProps={{ style: { width: "256px" } }}>
				<Toolbar>
					<Stack mx={"auto"} direction={"row"} alignItems={"center"} justifyContent={"center"}>
						<Typography textAlign={"center"} color={"primary"} fontSize={25} mr={1} className="font-anta">
							Aircraft-MFG
						</Typography>
						<RocketLaunch color="primary" />
					</Stack>
				</Toolbar>
				<Divider />
				<List>
					{link.map((row, i) => (
						<ListItem key={i}>
							<ListItemButton
								onClick={() => {
									navi(row.link)
									setOpen("")
								}}
								sx={location.pathname === row.link ? { backgroundColor: "primary.main", color: "white", borderRadius: 2 } : null}
							>
								<ListItemIcon sx={location.pathname === row.link ? { color: "white" } : null}>{row.icon}</ListItemIcon>
								<ListItemText>{row.name}</ListItemText>
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</Drawer>

			<Outlet />
		</>
	)
}
